import CryptoJS from 'crypto-js'

export default {
  // https://stackoverflow.com/questions/44714599/cipher-a-string-using-crypto-js-with-hex-encoding-to-make-it-url-friendly
  sysEncrypt(data = '', base64Encode = false) {
    try {
      const encoded = CryptoJS.AES.encrypt(data, 'TJJM646AMSC27PT5F7M6ABUGC').toString()

      if (base64Encode) {
        return btoa(encoded)
      }

      return encoded
    } catch (e) {
      console.log('system error')
      return false
    }
  },
  // eslint-disable-next-line consistent-return
  sysDecrypt(data = '', base64Decode = false) {
    try {
      if (base64Decode) {
        // eslint-disable-next-line no-param-reassign
        data = atob(data)
      }

      return CryptoJS.AES.decrypt(data, 'TJJM646AMSC27PT5F7M6ABUGC').toString(CryptoJS.enc.Utf8)
    } catch (e) {
      console.log('system error')
      return false
    }
  },
}

import axios from '@/libs/axios'

const invoiceActions = {
    fetchInvoices({ commit, dispatch }, searchParams){
        return axios.get('/invoices/', { params: searchParams })
    },
    fetchInvoice({ commit, dispatch }, id) {
        return axios.get(`/invoices/${id}`)
    },
    storeInvoice({ commit, dispatch }, data) {
        return axios.post('/invoices', data)
    },
    updateInvoice({ commit, dispatch }, data) {
        return axios.put(`/invoices/${data.id}`, data)
    },
    deleteInvoice({ commit, dispatch }, id) {
        return axios.delete(`/invoices/${id}`)
    },
}

export default invoiceActions

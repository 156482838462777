import axios from '@/libs/axios'

const legalPersonActions = {
    fetchLegalPersons({ commit, dispatch }, searchParams) {
        return axios.get('/legal-persons', { params: searchParams })
    },
    fetchLegalPersonsForSelect({ dispatch }) {
        return new Promise((resolve, reject) => {
            dispatch('fetchLegalPersons', {page: 1, limit: 10000})
                .then(response => {
                    const list = []

                    if (response.data.entities.data.length) {
                        for (const key in response.data.entities.data) {
                            list.push({
                                value: parseInt(response.data.entities.data[key].id),
                                title: response.data.entities.data[key].name,
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    },
    fetchLegalPerson({ commit, dispatch }, id) {
        return axios.get(`/legal-persons/${id}`)
    },
    storeLegalPerson({ commit, dispatch }, data) {
        return axios.post('/legal-persons', data)
    },
    updateLegalPerson({ commit, dispatch }, data) {
        return axios.put(`/legal-persons/${data.id}`, data)
    },
    deleteLegalPerson({ commit, dispatch }, id) {
        return axios.delete(`/legal-persons/${id}`)
    },
}

export default legalPersonActions

import axios from '@/libs/axios'
import i18nHelper from '@/libs/helper/i18nHelper'

const permissionActions = {
    fetchPermissionsForSelect() {
        return new Promise((resolve, reject) => {
            axios.get('/permissions')
                .then(response => {
                    const list = []

                    if (response.data.entities) {
                        for (const key in response.data.entities) {
                            list.push({
                                value: parseInt(key),
                                title: i18nHelper.localizePermission(response.data.entities[key]),
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    },
}

export default permissionActions

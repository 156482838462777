import storageHelper from '@/libs/helper/storageHelper'
import authHelper from '@/libs/helper/authHelper'
import cryptHelper from '@/libs/helper/cryptHelper'
import toastHelper from '@/libs/helper/toastHelper'
import stringHelper from '@/libs/helper/stringHelper'
import viewHelper from '@/libs/helper/viewHelper'
import numberHelper from "@/libs/helper/numberHelper";
import dateHelper from "@/libs/helper/dateHelper";
import objectHelper from "@/libs/helper/objectHelper";
import formHelper from "@/libs/helper/formHelper";

export default {
  ...storageHelper,
  ...authHelper,
  ...cryptHelper,
  ...toastHelper,
  ...stringHelper,
  ...viewHelper,
  ...numberHelper,
  ...dateHelper,
  ...objectHelper,
  ...formHelper,
}

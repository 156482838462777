import Vue from 'vue'
import VueRouter from 'vue-router'
import {canNavigate} from '@/libs/acl/routeProtection'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'
import store from '@/store'
import acl from '@/libs/permission'

// routes
import pages from '@/router/routes/pages'
import auth from '@/router/routes/auth'
import user from '@/router/routes/user'
import roles from '@/router/routes/roles'
import app from '@/router/routes/app'
import options from "@/router/routes/options"
import client from "@/router/routes/clients"
import fileManager from "@/router/routes/fileManager"
import emailTemplates from "@/router/routes/emailTemplates"
import legalPersons from "@/router/routes/legalPersons"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [
    ...pages,
    ...auth,
    ...user,
    ...roles,
    ...options,
    ...client,
    ...fileManager,
    ...emailTemplates,
    ...legalPersons,
    // always last one
    ...app,
  ],
})

// async call
function beforeRouterInit() {
  store.dispatch('initApp')
}

async function firstLoad() {
  await beforeRouterInit();
}

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!document.body.className.match('odin')) {
    firstLoad().finally(() => {
      if (process.env.NODE_ENV === 'development') console.log('router - beforeEach - firstLoad finished')
    })
    document.body.classList.add('odin')
  }

  if (to.meta.resource === 'Public' || to.meta.resource === 'Auth') {
    document.body.classList.add('public')
  } else if (document.body.className.match('public')) {
    document.body.classList.remove('public')
  }

  if (!canNavigate(to)) {
    if (to.meta.resource === 'Public') {
      return next({name: to.name})
    }

    // Redirect to login if not logged in
    if (!isLoggedIn) return next({name: 'login'})

    // If logged in => not authorized
    return next({name: 'misc-not-authorized'})
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  // ability-t ki kell írtani, addig is a belső acl itt fur meg
  const protectedResources = ['Auth', 'Public']
  if (!protectedResources.includes(to.meta.resource) && !acl.can(to.meta.resource || '*', to.meta.action || 'all')) {
    return next({name: 'error-404'})
  }

  // minden rendben, akkor betöltjük
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

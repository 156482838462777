import axios from '@/libs/axios'

const fileManagerActions = {
  fetchFilesForFileManager() {
    return axios.get('/fileManager')
  },
  fetchFolderForFileManager({commit, dispatch}, id) {
    return axios.get(`/fileManager/${id}`)
  },
  createNewFolder({commit, dispatch}, data) {
    return axios.post('/fileManager/newFolder', data)
  },
  renameFolder({ commit, dispatch }, data) {
    return axios.post(`/fileManager/folders/rename/${data.id}`, data)
  },
  downloadFolderAsZip({ commit, dispatch }, id) {
    return axios.post(`/fileManager/folders/download/${id}`)
  },
  deleteFolder({ commit, dispatch }, id) {
    return axios.delete(`/fileManager/folders/${id}`)
  },
  uploadFile({commit, dispatch}, data) {
    return axios.post('/fileManager/newFile', data)
  },
  renameFile({ commit, dispatch }, data) {
    return axios.post(`/fileManager/files/rename/${data.id}`, data)
  },
}

export default fileManagerActions

import helpers from '@/libs/helper'

const userMutations = {
  SET_TOKEN(state, token) {
    state.token = token
    helpers.setLocalStorageItem('token', token)
  },
  SET_USER_DATA(state, userData) {
    userData.role = userData.roles[0].name

    // eslint-disable-next-line no-param-reassign
    userData.ability = [
      { action: 'manage', subject: 'all' },
    ]

    state.user = userData
    helpers.setLocalStorageItem('userData', JSON.stringify(userData))
    // ability.update(userData.ability)
  },
  CLEAR_USER_DATA(state) {
    state.token = ''
    state.user = null
    helpers.deleteLocalStorageItem('token')
    helpers.deleteLocalStorageItem('userData')
  },
}

export default userMutations

import axios from '@/libs/axios'

const clientActions = {
    fetchClients({ commit, dispatch }, searchParams) {
        return axios.get('/clients', { params: searchParams })
    },
    fetchClientStatuses({}) {
        return axios.get('/clients/statuses')
    },
    fetchClientBillingSystems({}) {
        return axios.get('/clients/billing-systems')
    },
    fetchClientSkipUserIds({ commit, dispatch }, id){
        return axios.get(`/clients/${id}/skip-user-ids`)
    },
    fetchClientNavCredentialStatuses({}) {
        return axios.get('/clients/nav-credential-statuses')
    },
    fetchClientNavCredentials({ commit, dispatch }, id){
        return axios.get(`/clients/${id}/nav-credentials`)
    },
    updateClientNavCredentials({ commit, dispatch }, data){
        return axios.post(`/clients/${data.clientId}/nav-credentials`, data)
    },
    fetchClientBillingSystemCredentialStatuses({}) {
        return axios.get('/clients/billing-system-credential-statuses')
    },
    fetchClientBillingSystemCredentials({ commit, dispatch }, id){
        return axios.get(`/clients/${id}/billing-system-credentials`)
    },
    updateClientBillingSystemCredentials({ commit, dispatch }, data){
        return axios.post(`/clients/${data.clientId}/billing-system-credentials`, data)
    },
    fetchClient({ commit, dispatch }, id) {
        return axios.get(`/clients/${id}`)
    },
    storeClient({ commit, dispatch }, data) {
        return axios.post('/clients', data)
    },
    updateClient({ commit, dispatch }, data) {
        return axios.put(`/clients/${data.id}`, data)
    },
    deleteClient({ commit, dispatch }, id) {
        return axios.delete(`/clients/${id}`)
    },
}

export default clientActions

export default [
  {
    path: '/clients',
    name: 'clients',
    component: () => import('@/views/pages/client/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.clients',
      resource: 'client',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.clients',
          active: true,
        },
      ],
    },
  },
  {
    path: '/clients/add',
    name: 'add-client',
    component: () => import('@/views/pages/client/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.clients',
      resource: 'client',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.clients',
          active: false,
          to: {name: 'clients'}
        },
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/clients/modify/:id',
    name: 'modify-client',
    component: () => import('@/views/pages/client/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.clients',
      resource: 'client',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.clients',
          active: false,
          to: {name: 'clients'}
        },
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
  {
    path: '/clients/documents/:id',
    name: 'client-documents',
    component: () => import('@/views/pages/client/document/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.documents',
      resource: 'invoice',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.clients',
          active: false,
          to: {name: 'clients'}
        },
        {
          text: 'breadcrumb.documents',
          active: true,
        },
      ],
    },
  },
  {
    path: '/clients/documents/:id/invoice/add/inbound',
    name: 'add-client-inbound-invoice',
    component: () => import('@/views/pages/client/document/invoice/AddInbound.vue'),
    meta: {
      pageTitle: 'breadcrumb.documents',
      resource: 'invoice',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.clients',
          active: false,
          to: {name: 'clients'}
        },
        {
          text: 'breadcrumb.documents',
          active: false,
          to: {name: 'client-documents'}
        },
        {
          text: 'breadcrumb.invoices',
          active: false,
          to: {name: 'client-documents'}
        },
        {
          text: 'breadcrumb.addInboundInvoice',
          active: true,
        },
      ],
    },
  },
  {
    path: '/clients/documents/:id/invoice/add/outbound',
    name: 'add-client-outbound-invoice',
    component: () => import('@/views/pages/client/document/invoice/AddOutbound.vue'),
    meta: {
      pageTitle: 'breadcrumb.documents',
      resource: 'invoice',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.clients',
          active: false,
          to: {name: 'clients'}
        },
        {
          text: 'breadcrumb.documents',
          active: false,
          to: {name: 'client-documents'}
        },
        {
          text: 'breadcrumb.invoices',
          active: false,
          to: {name: 'client-documents'}
        },
        {
          text: 'breadcrumb.addOutboundInvoice',
          active: true,
        },
      ],
    },
  },
]

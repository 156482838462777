import * as moment from 'moment'

export default {
  formatLaravelTimestampToDate(datetime) {
    const mDate = moment(datetime)
    return mDate.isValid() ? mDate.format('YYYY-MM-DD') : '-'
  },
  formatLaravelTimestampToDateTime(datetime) {
    const mDate = moment(datetime)
    return mDate.isValid() ? mDate.format('YYYY-MM-DD HH:mm:ss') : '-'
  }
}

import axios from '@/libs/axios'

const roleActions = {
    fetchRoleList({ commit, dispatch }, searchParams){
        return axios.get('/roles/list', { params: searchParams })
    },
    fetchRolesForSelect({ dispatch }) {
        return new Promise((resolve, reject) => {
            dispatch('fetchRoleList')
              .then(response => {
                  const list = []

                  if (response.data.entities) {
                      for (const key in response.data.entities) {
                          list.push({
                              value: parseInt(response.data.entities[key].id),
                              title: response.data.entities[key].name,
                          })
                      }
                  }

                  resolve(list)
              }).catch(error => {
                reject(error)
            })
        })
    }, 
    fetchRoles({ commit, dispatch }, searchParams) {
        return axios.get('/roles', { params: searchParams })
    },
    fetchRole({ commit, dispatch }, id) {
        return axios.get(`/roles/${id}`)
    },
    storeRole({ commit, dispatch }, data) {
        return axios.post('/roles', data)
    },
    updateRole({ commit, dispatch }, data) {
        return axios.put(`/roles/${data.id}`, data)
    },
    deleteRole({ commit, dispatch }, id) {
        return axios.delete(`/roles/${id}`)
    },
}

export default roleActions

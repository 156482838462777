import axios from '@/libs/axios'
import helpers from '@/libs/helper'

import permissionActions from '@/store/taa/actions/permissionActions'
import roleActions from '@/store/taa/actions/roleActions'
import appActions from '@/store/taa/actions/appActions'
import authActions from '@/store/taa/actions/authActions'
import userActions from '@/store/taa/actions/userActions'
import logActions from "@/store/taa/actions/logActions"
import optionActions from "@/store/taa/actions/optionActions"
import clientActions from "@/store/taa/actions/clientActions"
import countryActions from "@/store/taa/actions/countryActions"
import taxNumberActions from "@/store/taa/actions/taxNumberActions"
import contactActions from "@/store/taa/actions/contactActions"
import invoiceActions from "@/store/taa/actions/invoiceActions"
import store from '@/store'
import fileActions from "@/store/taa/actions/fileActions"
import fileManagerActions from "@/store/taa/actions/fileManagerActions"
import emailTemplateActions from "@/store/taa/actions/emailTemplateActions";
import legalPersonActions from "@/store/taa/actions/legalPersonActions";

// setup axios
axios.interceptors.request.use(
  config => {
    const accessToken = store.state.token
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    if (process.env.NODE_ENV === 'development') console.log('Interceptor request[', config.method, '+', config.url, ']', config);
    return config
  },
  error => {
    // Do something with request error
    helpers.showErrorToast('', 'Ismeretlen hiba! Keresse fel az oldal üzemeltetőjét')
    if (process.env.NODE_ENV === 'development') console.error('Interceptor request error: ', error);
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  //If we have a response from our recent http call
  (response) => {
    if (process.env.NODE_ENV === 'development') console.log('Interceptor response[', response.config.method, '+', response.config.url, ']', response);
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      helpers.showErrorToast('Lejárt munkamenet!')
      helpers.logout()
      // eslint-disable-next-line no-underscore-dangle
    }
    //Catches 403 error from our axios request
    if (process.env.NODE_ENV === 'development') console.error('Interceptor response error: ', error);
    return Promise.reject(error);
  }
);

const actions = {
  ...permissionActions,
  ...roleActions,
  ...appActions,
  ...authActions,
  ...userActions,
  ...optionActions,
  ...clientActions,
  ...countryActions,
  ...taxNumberActions,
  ...contactActions,
  ...invoiceActions,
  ...fileActions,
  ...fileManagerActions,
  ...emailTemplateActions,
  ...legalPersonActions,
  ...logActions,
}

export default actions

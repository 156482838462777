import axios from '@/libs/axios'

const emailTemplateActions = {
    fetchEmailTemplates({ commit, dispatch }, searchParams) {
        // todo fontos get hívásnál hogy a params object key alatt menjenek a keresőparaméterek
        return axios.get('/email-templates', { params: searchParams })
    },
    fetchEmailTemplate({ commit, dispatch }, id) {
        return axios.get(`/email-templates/${id}`)
    },
    updateEmailTemplate({ commit, dispatch }, data) {
        return axios.put(`/email-templates/${data.id}`, data)
    },
}

export default emailTemplateActions

import axios from '@/libs/axios'

const contactActions = {
    fetchContacts({ commit, dispatch }, searchParams) {
        return axios.get('/contacts', { params: searchParams })
    },
    fetchContactStatuses({}) {
        return axios.get('/contacts/statuses')
    },
    fetchContactTypes({}) {
        return axios.get('/contacts/types')
    },
    fetchContact({ commit, dispatch }, id) {
        return axios.get(`/contacts/${id}`)
    },
    storeContact({ commit, dispatch }, data) {
        return axios.post('/contacts', data)
    },
    updateContact({ commit, dispatch }, data) {
        return axios.put(`/contacts/${data.id}`, data)
    },
    deleteContact({ commit, dispatch }, id) {
        return axios.delete(`/contacts/${id}`)
    },
}

export default contactActions

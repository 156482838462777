import axios from '@/libs/axios'

const userActions = {
    fetchUsers({ commit, dispatch }, searchParams) {
        return axios.get('/users', { params: searchParams })
    },
    fetchUserStatuses({}) {
        return axios.get('/users/statuses')
    },
    fetchUsersForSelect({ dispatch }) {
        return new Promise((resolve, reject) => {
            dispatch('fetchUsers', {page: 1, limit: 10000})
                .then(response => {
                    const list = []

                    if (response.data.entities.data.length) {
                        for (const key in response.data.entities.data) {
                            list.push({
                                value: parseInt(response.data.entities.data[key].id),
                                title: response.data.entities.data[key].username + ' (' + response.data.entities.data[key].name + ')',
                                role: response.data.entities.data[key].roles[0].name
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    },
    fetchUser({ commit, dispatch }, id) {
        return axios.get(`/users/${id}`)
    },
    storeUser({ commit, dispatch }, data) {
        return axios.post('/users', data)
    },
    updateUser({ commit, dispatch }, data) {
        return axios.put(`/users/${data.id}`, data)
    },
    deleteUser({ commit, dispatch }, id) {
        return axios.delete(`/users/${id}`)
    },
}

export default userActions

import cryptHelper from '@/libs/helper/cryptHelper'

const storageHelper = {
  get(key) {
    const data = localStorage.getItem(key)

    if (data) {
      return cryptHelper.sysDecrypt(data)
    }

    return false
  },
  set(key, data) {
    return localStorage.setItem(key, cryptHelper.sysEncrypt(data))
  },
}

export default storageHelper

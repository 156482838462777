import moment from "moment";

const dateHelper = {
  getCurrentYear(){
    return moment(new Date()).year()
  },
  getCurrentDate(){
    return moment(new Date()).format('YYYY-MM-DD')
  }
}

export default dateHelper

import axios from '@/libs/axios'

const fileActions = {
  fetchFile({commit, dispatch}, id) {
    return axios.get(`/files/${id}`)
  },
  downloadFile({commit, dispatch}, id) {
    return axios.post(`/files/download/${id}`, [], {responseType: 'blob'})
  },
  getTemporaryUrlForFile({commit, dispatch}, id) {
    return axios.get(`/files/getTemporaryUrl/${id}`)
  },
  uploadInvoice({commit, dispatch}, data) {
    return axios.post('/files/uploadInvoice', data)
  },
  deleteFile({commit, dispatch}, id) {
    return axios.delete(`/files/${id}`)
  },
}

export default fileActions

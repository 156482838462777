export default [
  {
    path: '/file-manager',
    name: 'file-manager',
    component: () => import('@/views/pages/fileManager/FileManager.vue'),
    meta: {
      pageTitle: 'breadcrumb.fileManager',
      resource: 'fileManager',
      action: 'all',
      breadcrumb: [
        {
          text: 'breadcrumb.fileManager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/file-manager/:id',
    name: 'file-manager-folder',
    component: () => import('@/views/pages/fileManager/FileManager.vue'),
    meta: {
      pageTitle: 'breadcrumb.fileManager',
      resource: 'fileManager',
      action: 'all',
      navActiveLink: 'file-manager',
      breadcrumb: [
        {
          text: 'breadcrumb.fileManager',
          active: true,
        },
      ],
    },
  },
]

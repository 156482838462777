import VueI18n from '@/libs/i18n'
import { localize } from 'vee-validate'

if (localStorage.getItem('locale')) {
  VueI18n.locale = localStorage.getItem('locale')
}

window.i18n = VueI18n

// set validation locale
localize(VueI18n.locale)

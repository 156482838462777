export default [
  {
    path: '/legal-persons',
    name: 'legal-persons',
    component: () => import('@/views/pages/legalPerson/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.legalPersons',
      resource: 'legalPerson',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.legalPersons',
          active: true,
        },
      ],
    },
  },
  {
    path: '/legal-persons/add',
    name: 'add-legal-person',
    component: () => import('@/views/pages/legalPerson/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.legalPersons',
      resource: 'legalPerson',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.legalPersons',
          active: false,
          to: {name: 'legal-persons'}
        },
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/legal-persons/modify/:id',
    name: 'modify-legal-person',
    component: () => import('@/views/pages/legalPerson/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.legalPersons',
      resource: 'legalPerson',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.legalPersons',
          active: false,
          to: {name: 'legal-persons'}
        },
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]

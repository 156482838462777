export default [
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/views/pages/role/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.roles',
      resource: 'role',
      action: 'list',
      breadcrumb: [
        {
          text: 'breadcrumb.roles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles/add',
    name: 'add-role',
    component: () => import('@/views/pages/role/Add.vue'),
    meta: {
      pageTitle: 'breadcrumb.roles',
      resource: 'role',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.roles',
          active: false,
          to: {name: 'roles'}
        },
        {
          text: 'breadcrumb.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles/modify/:id',
    name: 'modify-role',
    component: () => import('@/views/pages/role/Modify.vue'),
    meta: {
      pageTitle: 'breadcrumb.roles',
      resource: 'role',
      action: 'edit',
      breadcrumb: [
        {
          text: 'breadcrumb.roles',
          active: false,
          to: {name: 'roles'}
        },
        {
          text: 'breadcrumb.modify',
          active: true,
        },
      ],
    },
  },
]

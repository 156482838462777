import router from '@/router'
import ability from '@/libs/acl/ability'
import { initialAbility } from '@/libs/acl/config'
// eslint-disable-next-line import/no-cycle
import store from '@/store/index'

export default {
  logout() {
    store.commit('CLEAR_USER_DATA')
    ability.update(initialAbility)
    if (router.currentRoute.name !== 'login') {
      router.push({ name: 'login' })
    }
  },
}

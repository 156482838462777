import axios from '@/libs/axios'
import i18nHelper from "@/libs/helper/i18nHelper";

const countryActions = {
  fetchCountriesForSelect() {
    return new Promise((resolve, reject) => {
      axios.get('/countries')
        .then(response => {
          const list = []

          if (response.data.values) {
            for (const key in response.data.values) {
              list.push({
                value: response.data.values[key].value,
                title: i18nHelper.localizeCountry(response.data.values[key].value),
              })
            }
          }

          resolve(list)
        }).catch(error => {
        reject(error)
      })
    })
  },
}

export default countryActions

import axios from '@/libs/axios'

const authActions = {
  login({ commit, dispatch }, credentials) {
    return new Promise((resolve, reject) => {
      axios({ url: '/sanctum/csrf-cookie', baseURL: process.env.VUE_APP_SERVER_BASE_URL, withCredentials: true }).then(response => {
        axios.post('/login', credentials).then(loginResponse => {
          commit('SET_TOKEN', loginResponse.data.token)
          resolve(loginResponse)
        })
        .catch(error => {
          reject(error)
        })
      });
    })
  },
  logout() {
    return axios.post('/logout')
  },
  getCurrentUser() {
    return axios.get('/me')
  },
  initCurrentUser({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch('getCurrentUser').then(response => {
        commit('SET_USER_DATA', response.data.data)
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
    })
  }
}

export default authActions

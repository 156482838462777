import store from '@/store/index'
import helper from '@/libs/helper'

export default {
    can(role, method) {
        if (role === '*' || role === undefined) {
            return true
        }

        if (store.getters.userPermissions === null) {
            return false
        }

        if (this.canRole(role)) {
            if (method === 'all') {
                return true
            }

            return store.getters.userPermissions[role].includes(method)
        }

        return false;
    },
    canRole(role) {
        if (role === '*' || role === undefined) {
            return true
        }

        if (!store.getters.userPermissions) {
            return false
        }

        return store.getters.userPermissions[role] !== undefined
    },
    canListPermission(role) {
        return this.can(role, 'list')
    },
    canCreatePermission(role) {
        return this.can(role, 'create')
    },
    canEditPermission(role) {
        return this.can(role, 'edit')
    },
    canDeletePermission(role) {
        return this.can(role, 'delete')
    },
    apiEPCheck(role, method) {
        return new Promise((resolve, reject) => {
            if (!this.can(role, method)) {
                helper.showWarningToast('Jogosulatlan használat')
                console.error(`Jogosulatlan használat(${role}/${method})`)
                reject()
            }
            resolve()
        })
    }
}

export default {
  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  slugify(text, separator = '-') {
    return text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, separator)
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, separator)
  }
}

import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
    withCredentials: true,
    // crossDomains: true,
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Partner-TaxNumber': process.env.VUE_APP_PARTNER_TAX_NUMBER
    },
})

Vue.prototype.$http = axiosIns

export default axiosIns
